/* You can add global styles to this file, and also import other style files */

html,
body {
  margin: 0;
  background: #81bb40;
  /* christmas cursor */
  /*cursor: url('./assets/images/gingerbread_cursor.png'), auto !important;*/

  .header-actions {
    text-align: right;
    margin-bottom: 10px;
  }

  strong {
    color: #333;
  }

  ul {
    padding: 0;
    margin-left: 10px;
    list-style: none;

    li {
      font-size: 16px;
      margin-top: 5px;
    }
  }

  &::ng-deep .mat-card {
    padding-bottom: 40px;
  }

  &::ng-deep table {
    width: 100%;
  }

  &::ng-deep hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #82BC49;
    margin: 1em 0;
    padding: 0;
  }

  &::ng-deep .mat-card .mat-raised-button {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  /* christmas pointer */
  /*
  a,
  .mat-icon,
  .mat-fab,
  .mat-option,
  .mat-select,
  .mat-button,
  .mat-checkbox,
  .mat-list-item,
  .mat-mini-fab,
  .mat-icon-button,
  .mat-button-toggle,
  .mat-slide-toggle,
  .mat-slide-toggle-bar,
  .mat-radio-button,
  .mat-raised-button {
    cursor: url('./assets/images/gingerbread_pointer.png'), auto !important;
  }
  */
}

.table {
  width: 100%;
}

.table th {
  color: #333;
  font-weight: 500;
}

.table th:last-child,
.table td:last-child {
  text-align: center;
}

.full-width {
  width: 100%;
}

.medium-width {
  width: 50% !important;
}

.snackbar-error {
  background: #C62828;
}

.snackbar-warning {
  background: #FF9800;
}

.snackbar-info {
  background: #536DFE;
}

.snackbar-success {
  background: #82bc49;
}